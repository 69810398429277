<template>
  <div class="form">
    <form-header :title="$t('createProject')" :cancel-url="urls.cancel">
      <button-gcr
        data-test-id="btn-submit-header"
        btn-class="btn-primary btn-sm form__header__btn"
        label="createProject"
        :disabled="isReadOnly"
        :loading="submitting"
        @btnclick="validateAnd(createProject)"
      />
    </form-header>

    <form ref="form">
      <new-project-form :project="project" />
    </form>

    <form-footer :cancel-url="urls.cancel">
      <button-gcr
        data-test-id="btn-submit-footer"
        btn-class="btn-primary btn-sm form__footer__btn"
        label="createProject"
        :disabled="isReadOnly"
        :loading="submitting"
        @btnclick="validateAnd(createProject)"
      />
    </form-footer>
  </div>
</template>

<script>
import FormHeader from '@/components/molecules/FormHeader'
import FormFooter from '@/components/molecules/FormFooter'
import NewProjectForm from '@/components/organism/NewProjectForm'
import ButtonGcr from '@/components/atoms/Button'
import { Consts, Urls } from '@/_helpers'
import alerts from '@/_helpers/alerts'
import createProject from '@/graphql/accreditations/projects/createProject.graphql'
import { mapGetters } from 'vuex'
import formDirty from '@/components/mixins/form-dirty.js'
import Project from '@/_models/Project'

export default {
  components: { FormFooter, FormHeader, NewProjectForm, ButtonGcr },
  mixins: [formDirty],
  data() {
    return {
      submitting: false,
      project: Project.empty(),
      urls: {
        cancel: { name: Urls.PROJECTS }
      }
    }
  },
  computed: {
    ...mapGetters({
      accreditationId: Consts.GETTER_ACCREDITATION_CURRENT_ID,
      isReadOnly: Consts.GETTER_USER_ISSAC
    })
  },
  methods: {
    async validateAnd(action) {
      try {
        if (!this.validateForm()) return

        this.submitting = true
        await action()
      } finally {
        this.submitting = false
      }
    },
    async createProject() {
      const projectId =  await this.$apollo
        .mutate({
          mutation: createProject,
          variables: {
            details: {
              accreditationId: this.accreditationId,
              ...this.project
            }
          }
        })
        .then(response => response.data.createProject.project.id)
      this.close(projectId)
    },
    close(id) {
      this.dirty = false
      alerts.showInfo('projectCreatedSucces')
      this.$router.push({
        name: Urls.PROJECT_DETAILS,
        query: { id }
      })
    },
    validateForm() {
      this.$refs.form.classList.add('was-validated')
      return this.$refs.form.checkValidity()
    }
  }
}
</script>
