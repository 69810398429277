export default {
  empty() {
    return {
      name: null,
      city: null,
      type: null,
      lotNumber: null,
      plannedUnitCount: null,
      totalConstructionCost: null,
      fundingAmount: null,
      fundingSource: null
    }
  }
}
