<template>
  <div v-if="isCoOwnership">
    <h3 data-test-id="header" class="form__subtitle">{{ $t('projectCostAndFunding') }}</h3>
    <div class="row">
      <cash
        data-test-id="total-construction-cost"
        class="form__col col-auto"
        :label="$t('totalConstructionCost')"
        v-model="project.totalConstructionCost"
        :validation-feedback="totalConstructionCostValidationFeedback"
      />
    </div>
    <div class="row">
      <cash
        data-test-id="funding-amount"
        class="form__col col-auto"
        :label="$t('fundingAmount')"
        :tooltip="$t('fundingAmountInfo')"
        v-model="project.fundingAmount"
        :validation-feedback="fundingAmountValidationFeedback"
      />
      <input-gcr
        data-test-id="funding-source"
        class="form__col col-12 col-md"
        label="fundingSource"
        :tooltip="$t('fundingSourceInfo')"
        v-model.trim="project.fundingSource"
        :validation-feedback="fundingSourceValidationFeedback"
      />
    </div>
  </div>
</template>

<script>
import Cash from '@/components/atoms/Cash'
import InputGcr from '@/components/atoms/Input'
import Validators from '@/_helpers/validators'

export default {
  components: { Cash, InputGcr },
  props: {
    project: Object
  },
  computed: {
    isCoOwnership() {
      return ['VERTICAL_CO_OWNERSHIP', 'HORIZONTAL_CO_OWNERSHIP'].includes(this.project.type)
    },
    totalConstructionCostValidationFeedback() {
      return Validators.required(this.project.totalConstructionCost, this.$t('totalConstructionCostRequired'))
    },
    fundingAmountValidationFeedback() {
      return Validators.test(this.hasFunding || !this.hasFundingSource && this.hasAnyFundingAmount, this.$t('fundingAmountRequired'))
    },
    fundingSourceValidationFeedback() {
      return Validators.test(this.hasFundingSource || !this.hasFunding , this.$t('fundingSourceRequired'))
    },
    hasAnyFundingAmount() {
      return this.project.fundingAmount != null
    },
    hasFunding() {
      return this.project.fundingAmount > 0
    },
    hasFundingSource() {
      return this.project.fundingSource
    }
  },
  watch: {
    isCoOwnership(newValue) {
      if (newValue) {
        this.project.fundingAmount |= 0
      } else {
        this.project.totalConstructionCost = null
        this.project.fundingAmount = null
        this.project.fundingSource = null
      }
    }
  }
}
</script>
