<template>
  <div class="form__body row">
    <div class="form__container col-xs-10 col-sm-8 col-md-6 offset-xs-1 offset-md-2">
      <div data-test-id="create-project-rules">
        <p class="form__text">{{ $t('createProjectRule2') }}</p>
        <p class="form__text">{{ $t('createProjectRule3') }}</p>
        <ul>
          <li class="form__text">{{ $t('createProjectRuleBullet1') }}</li>
          <li class="form__text">{{ $t('createProjectRuleBullet2') }}</li>
          <li class="form__text">{{ $t('createProjectRuleBullet3') }}</li>
        </ul>
      </div>
      <h3 class="form__subtitle">{{ $t('createProjectSubtitleProject') }}</h3>
      <div class="row">
        <div class="col form__col">
          <radio-group
            data-test-id="project-type"
            group="projectType"
            v-model="project.type"
            :options="projectTypeOptions"
            :label="$t('projectCreationType')"
            :validation-feedback="projectTypeValidationFeedback"
          />
          <div v-if="isHorizontalCoOwnership" data-test-id="integrated-project-note" class="col-12 input-desc" v-html="$t('integratedProjectNote')" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8 form__col">
          <input-gcr
            data-test-id="input-project-name"
            label="projectName"
            v-model.trim="project.name"
            :validation-feedback="projectNameValidationFeedback"
          />
          <p class="input-desc" data-test-id="project-name-description">{{ $t('projectNameDescription') }}</p>
        </div>
      </div>

      <div class="row">
        <search-select-gcr
          data-test-id="select-city"
          class="col-sm-8 form__col"
          :label="$t('city')"
          :placeholder="$t('select')"
          :no-options="$t('noData')"
          :options="cities"
          v-model.trim="project.city"
          :validation-feedback="projectCityValidationFeedback"
        />
      </div>

      <div v-if="isHorizontalCoOwnership" class="row">
        <input-gcr
          data-test-id="planned-unit-count"
          class="col-auto form__col"
          label="plannedUnitCount"
          numeric
          v-model="project.plannedUnitCount"
          inputmask="###"
          :validation-feedback="plannedUnitCountValidationFeedback"
        />
        <input-gcr
          data-test-id="common-lot-number"
          class="col-xs-6 form__col"
          label="commonLotNumber"
          v-model.trim="project.lotNumber"
          :validation-feedback="lotNumberValidationFeedback"
        />
      </div>

      <project-funding data-test-id="project-funding" :project="project" />

    </div>
  </div>
</template>

<script>
import RadioGroup from '@/components/atoms/RadioGroup'
import InputGcr from '@/components/atoms/Input'
import SearchSelectGcr from '@/components/atoms/SearchSelect'
import getCities from '@/graphql/getCities.graphql'
import Validators from '@/_helpers/validators'
import ProjectFunding from '@/components/organism/ProjectFunding'
import { mapState } from 'vuex'

export default {
  components: { RadioGroup, InputGcr, SearchSelectGcr, ProjectFunding },
  props: {
    project: {
      type: Object,
      required: true
    },
    disableProjectTypeEdition: {
      type: Boolean
    }
  },
  data() {
    return {
      cities: []
    }
  },
  apollo: {
    getCities: {
      query: getCities,
      variables: {
        filter: ''
      },
      update(data) {
        this.cities = data.cities.map(c => c.name)
      }
    }
  },
  computed: {
    ...mapState({
      multibuildingEnabled: state => state.features.multibuilding
    }),
    projectTypeValidationFeedback() {
      return Validators.required(this.project.type, this.$t('projectTypeRequired'))
    },
    projectNameValidationFeedback() {
      return Validators.required(this.project.name, this.$t('projectNameRequired'))
    },
    projectCityValidationFeedback() {
      return Validators.required(this.project.city, this.$t('cityRequired'))
    },
    lotNumberValidationFeedback() {
      return Validators.required(this.project.lotNumber, this.$t('lotNumberRequired'))
    },
    plannedUnitCountValidationFeedback() {
      return Validators.required(this.project.plannedUnitCount, this.$t('plannedUnitCountRequired'))
    },
    isHorizontalCoOwnership() {
      return this.project.type === 'HORIZONTAL_CO_OWNERSHIP'
    },
    soleOwnershipDisabled() {
      return this.disableProjectTypeEdition
    },
    verticalCoOwnershipDisabled() {
      return this.disableProjectTypeEdition
    },
    horizontalCoOwnershipDisabled() {
      return this.disableProjectTypeEdition || !this.multibuildingEnabled
    },
    projectTypeOptions() {
      return [
        { label: this.$t('projectTypeSoleOwnership'), value: 'SOLE_OWNERSHIP', disabled: this.soleOwnershipDisabled },
        { label: this.$t('projectTypeVerticalCoOwnership'), value: 'VERTICAL_CO_OWNERSHIP', disabled: this.verticalCoOwnershipDisabled },
        { label: this.$t('projectTypeHorizontalCoOwnership'), value: 'HORIZONTAL_CO_OWNERSHIP', disabled: this.horizontalCoOwnershipDisabled }
      ]
    }
  },
  watch: {
    isHorizontalCoOwnership(value) {
      if (!value) {
        this.project.lotNumber = null
        this.project.plannedUnitCount = null
      }
    }
  }
}
</script>
